import { Component } from 'react'
import styles from './index.module.sass'
import products from './products.yaml'
import { Slider } from '../_shared'

let asset = name => `/rnd/new-arrivals/${name}`

export class NewArrivals extends Component {
  render() {
    return <section id={styles.section}>
      <div id={styles.titles}>
        <h3>New arrivals</h3>
        <a href='/catalog'>View all</a>
      </div>
      <Slider id={styles.products}
        itemComp={Product}
        items={products}
        keyName='img'
        {...this.productsSliderOptions}
      />
    </section>
  }

  productsSliderOptions = {
    slidesPerView: 2.25,
    loop: true,
  }
}

function Product({ name, img, price, url }) {
  return <div className={styles.product}>
    <div className={styles.imageContainer}>
      <a href={"/product"}>
        <img src={asset(img)} />
      </a>
    </div>
    <div className={styles.text}>
      <p className={styles.name}>{name}</p>
      <p className={styles.price}>{price}</p>
    </div>
    <a className={styles.customize} href={"/product"}>Customize</a>
  </div>
}
