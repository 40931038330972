const Input = ({
  classnames,
  hasOutline = false,
  onChange = () => {},
  placeholder = "",
  style,
}) => (
  <input
    style={style}
    className={`h-11 w-full mb-2.5 rounded-full px-6 py-2.5 text-sm font-primary ${
      hasOutline ? "" : "focus:outline-none focus-visible:outline-none"
    } ${classnames}`}
    type="text"
    onChange={onChange}
    placeholder={placeholder}
  />
);

export default Input;
