import React, { useState } from "react";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import MainPageInput from "../MainPageInput";
import Logo from "../Logo";

function Arrow() {
  return <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.875 6.6123H10.125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.75 3.2373L10.125 6.6123L6.75 9.9873" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>  
}

function Paypal() {
  return <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.26155 2.89761C9.28183 2.75192 9.29242 2.60358 9.29242 2.45306C9.29242 1.18782 8.13252 0.162109 6.7017 0.162109H2.37204C2.09969 0.162109 1.86717 0.336025 1.82199 0.573509L0.226622 8.95857C0.169374 9.25945 0.431667 9.53364 0.776674 9.53364H2.36014C2.63248 9.53364 2.86938 9.35985 2.91454 9.12235C2.91454 9.12235 2.91718 9.10843 2.92195 9.08337H2.92198L2.70131 10.2432C2.65089 10.5084 2.88206 10.7501 3.18614 10.7501H4.57143C4.81148 10.7501 5.01644 10.5968 5.05624 10.3874L5.44979 8.31907C5.51764 7.96251 5.86671 7.70143 6.27556 7.70143H6.63949C8.61627 7.70143 10.2188 6.28436 10.2188 4.53634C10.2188 3.85993 9.84065 3.26202 9.26155 2.89761Z" fill="#002987"/>
  <path d="M9.26246 2.89795C9.01472 4.68009 7.29705 6.06005 5.21475 6.06005H4.00653C3.73383 6.06005 3.49832 6.22067 3.42444 6.44777L2.70225 10.2435C2.6518 10.5087 2.88297 10.7504 3.18705 10.7504H4.57235C4.8124 10.7504 5.01735 10.5971 5.05715 10.3878L5.45071 8.31939C5.51855 7.96284 5.86763 7.70176 6.27648 7.70176H6.6404C8.61718 7.70176 10.2197 6.28469 10.2197 4.53668C10.2197 3.86027 9.84156 3.26236 9.26246 2.89795Z" fill="#0085CC"/>
  <path d="M4.00789 6.06016H5.21611C7.29843 6.06016 9.0161 4.68013 9.26382 2.89792C8.89227 2.6641 8.43822 2.52612 7.94745 2.52612H4.79391C4.43487 2.52612 4.12831 2.75541 4.06872 3.06851L3.42578 6.44788C3.49966 6.22077 3.7352 6.06016 4.00789 6.06016Z" fill="#00186A"/>
  </svg>
}

function Visa() {
  return <svg width="27" height="8" viewBox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.97266 7.78229L11.2933 0.35376H13.3568L12.0774 7.78229H9.97266Z" fill="#3C58BF"/>
  <path d="M9.97266 7.78229L11.6647 0.35376H13.3568L12.0774 7.78229H9.97266Z" fill="#293688"/>
  <path d="M19.5486 0.43587C19.1359 0.270791 18.4756 0.105713 17.6502 0.105713C15.5867 0.105713 14.101 1.13745 14.101 2.62316C14.101 3.73744 15.1328 4.31521 15.9582 4.68664C16.7836 5.05807 17.0312 5.30569 17.0312 5.63584C17.0312 6.13108 16.3709 6.3787 15.7931 6.3787C14.9677 6.3787 14.5137 6.25489 13.8122 5.966L13.5233 5.84219L13.2344 7.53425C13.7296 7.74059 14.6375 7.94694 15.5867 7.94694C17.774 7.94694 19.2185 6.9152 19.2185 5.34696C19.2185 4.48029 18.682 3.81998 17.4439 3.28347C16.701 2.91205 16.2471 2.7057 16.2471 2.33427C16.2471 2.00412 16.6185 1.67396 17.4439 1.67396C18.1455 1.67396 18.6407 1.79777 19.0121 1.96285L19.2185 2.04538L19.5486 0.43587Z" fill="#3C58BF"/>
  <path d="M19.5486 0.43587C19.1359 0.270791 18.4756 0.105713 17.6502 0.105713C15.5867 0.105713 14.4725 1.13745 14.4725 2.62316C14.4725 3.73744 15.1328 4.31521 15.9582 4.68664C16.7836 5.05807 17.0312 5.30569 17.0312 5.63584C17.0312 6.13108 16.3709 6.3787 15.7931 6.3787C14.9677 6.3787 14.5137 6.25489 13.8122 5.966L13.5233 5.84219L13.2344 7.53425C13.7296 7.74059 14.6375 7.94694 15.5867 7.94694C17.774 7.94694 19.2185 6.9152 19.2185 5.34696C19.2185 4.48029 18.682 3.81998 17.4439 3.28347C16.701 2.91205 16.2471 2.7057 16.2471 2.33427C16.2471 2.00412 16.6185 1.67396 17.4439 1.67396C18.1455 1.67396 18.6407 1.79777 19.0121 1.96285L19.2185 2.04538L19.5486 0.43587Z" fill="#293688"/>
  <path d="M23.0979 0.35376C22.6027 0.35376 22.2313 0.39503 22.0249 0.890265L18.9297 7.78229H21.1582L21.5709 6.5442H24.2122L24.4598 7.78229H26.4408L24.7074 0.35376H23.0979ZM22.1487 5.30612C22.2725 4.93469 22.9741 3.11883 22.9741 3.11883C22.9741 3.11883 23.1392 2.66486 23.263 2.37597L23.3868 3.07756C23.3868 3.07756 23.7995 4.93469 23.882 5.34739H22.1487V5.30612Z" fill="#3C58BF"/>
  <path d="M23.5932 0.35376C23.0979 0.35376 22.7265 0.39503 22.5201 0.890265L18.9297 7.78229H21.1582L21.5709 6.5442H24.2122L24.4598 7.78229H26.4408L24.7074 0.35376H23.5932ZM22.1487 5.30612C22.3138 4.89342 22.9741 3.11883 22.9741 3.11883C22.9741 3.11883 23.1392 2.66486 23.263 2.37597L23.3868 3.07756C23.3868 3.07756 23.7995 4.93469 23.882 5.34739H22.1487V5.30612Z" fill="#293688"/>
  <path d="M6.17725 5.55397L5.97091 4.48096C5.59948 3.24287 4.40266 1.88097 3.08203 1.22066L4.93916 7.8238H7.16772L10.5106 0.395264H8.282L6.17725 5.55397Z" fill="#3C58BF"/>
  <path d="M6.17725 5.55397L5.97091 4.48096C5.59948 3.24287 4.40266 1.88097 3.08203 1.22066L4.93916 7.8238H7.16772L10.5106 0.395264H8.6947L6.17725 5.55397Z" fill="#293688"/>
  <path d="M0.441406 0.35376L0.812833 0.436299C3.45409 1.05534 5.26995 2.62359 5.97154 4.48072L5.22868 0.972804C5.10488 0.477569 4.73345 0.35376 4.27948 0.35376H0.441406Z" fill="#FFBC00"/>
  <path d="M0.441406 0.35376C3.08266 0.972804 5.26995 2.58232 5.97154 4.43945L5.26995 1.50931C5.14614 1.01407 4.73345 0.725187 4.27948 0.725187L0.441406 0.35376Z" fill="#F7981D"/>
  <path d="M0.441406 0.35376C3.08266 0.972804 5.26995 2.58232 5.97154 4.43945L5.4763 2.82994C5.35249 2.3347 5.18741 1.83947 4.60964 1.63312L0.441406 0.35376Z" fill="#ED7C00"/>
  <path d="M8.24243 5.30543L6.83927 3.90226L6.17895 5.47051L6.01387 4.43877C5.64245 3.20068 4.44563 1.83878 3.125 1.17847L4.98213 7.78161H7.21069L8.24243 5.30543Z" fill="#051244"/>
  <path d="M12.0813 7.78242L10.3067 5.96655L9.97656 7.78242H12.0813Z" fill="#051244"/>
  <path d="M16.8248 5.2229C16.9899 5.38798 17.0725 5.51179 17.0312 5.67687C17.0312 6.1721 16.3709 6.41972 15.7931 6.41972C14.9677 6.41972 14.5137 6.29591 13.8122 6.00702L13.5233 5.88322L13.2344 7.57527C13.7296 7.78162 14.6375 7.98797 15.5867 7.98797C16.9074 7.98797 17.9804 7.61654 18.5994 6.95623L16.8248 5.2229Z" fill="#051244"/>
  <path d="M19.2188 7.78157H21.1584L21.5711 6.54348H24.2124L24.46 7.78157H26.4409L25.7394 4.76888L23.2632 2.37524L23.387 3.03556C23.387 3.03556 23.7997 4.89269 23.8822 5.30539H22.1489C22.314 4.89269 22.9743 3.1181 22.9743 3.1181C22.9743 3.1181 23.1394 2.66413 23.2632 2.37524" fill="#051244"/>
  </svg>  
}

function Maestro() {
  return <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.73769 9.76323C8.38877 9.76323 10.5379 7.61411 10.5379 4.96304C10.5379 2.31196 8.38877 0.162842 5.73769 0.162842C3.08662 0.162842 0.9375 2.31196 0.9375 4.96304C0.9375 7.61411 3.08662 9.76323 5.73769 9.76323Z" fill="#EE0005"/>
  <path d="M12.1361 9.76274C14.7872 9.76274 16.9363 7.61362 16.9363 4.96255C16.9363 2.31147 14.7872 0.162354 12.1361 0.162354C9.48506 0.162354 7.33594 2.31147 7.33594 4.96255C7.33594 7.61362 9.48506 9.76274 12.1361 9.76274Z" fill="#F9A000"/>
  <path d="M7.35156 4.96269C7.35156 6.42146 7.97681 7.72128 8.95163 8.56284C9.92641 7.72125 10.5517 6.42146 10.5517 4.96269C10.5517 3.50393 9.92644 2.2041 8.95163 1.36255C7.97684 2.20417 7.35156 3.50397 7.35156 4.96269Z" fill="#FF6300"/>
  </svg>  
}

function Discover() {
  return <svg width="31" height="6" viewBox="0 0 31 6" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
  <path d="M2.54645 4.60374C2.25551 4.86178 1.87756 4.97435 1.27924 4.97435H1.03073V1.89115H1.27924C1.87756 1.89115 2.24063 1.9963 2.54645 2.26823C2.8667 2.54831 3.05936 2.98227 3.05936 3.42895C3.05936 3.87652 2.8667 4.32378 2.54645 4.60374ZM1.46473 1.10119H0.105469V5.76367H1.45756C2.17655 5.76367 2.69569 5.59708 3.15142 5.22543C3.69294 4.78527 4.01318 4.12185 4.01318 3.43566C4.01318 2.05971 2.96646 1.10119 1.46473 1.10119Z" fill="#201D1C"/>
  <path d="M4.4375 5.76392H5.36349V1.10143H4.4375V5.76392Z" fill="#201D1C"/>
  <path d="M7.63032 2.89072C7.07465 2.68878 6.91147 2.55558 6.91147 2.30367C6.91147 2.01003 7.20217 1.78688 7.6013 1.78688C7.87877 1.78688 8.10678 1.89881 8.34805 2.16449L8.83253 1.54137C8.43438 1.19932 7.95801 1.02445 7.43752 1.02445C6.5976 1.02445 5.95691 1.59734 5.95691 2.36042C5.95691 3.00278 6.25524 3.33165 7.12493 3.63906C7.4874 3.76463 7.67189 3.84831 7.76501 3.9046C7.95004 4.02325 8.04268 4.19122 8.04268 4.38702C8.04268 4.76481 7.73685 5.04471 7.3237 5.04471C6.88204 5.04471 6.52628 4.82777 6.3131 4.42284L5.71484 4.98855C6.14148 5.60362 6.65386 5.87622 7.35843 5.87622C8.32076 5.87622 8.99578 5.24782 8.99578 4.34521C8.99578 3.60443 8.68369 3.2691 7.63032 2.89072Z" fill="#201D1C"/>
  <path d="M9.28906 3.43574C9.28906 4.80621 10.3847 5.8689 11.7948 5.8689C12.1934 5.8689 12.5348 5.79192 12.9558 5.59715V4.52659C12.5856 4.89049 12.2577 5.03725 11.8378 5.03725C10.9053 5.03725 10.2433 4.37318 10.2433 3.42902C10.2433 2.53384 10.9262 1.8277 11.7948 1.8277C12.2365 1.8277 12.5708 1.98249 12.9558 2.35232V1.28227C12.5493 1.07974 12.2151 0.995864 11.8167 0.995864C10.4138 0.995864 9.28906 2.08005 9.28906 3.43574Z" fill="#201D1C"/>
  <path d="M20.2971 4.23324L19.0311 1.10143H18.0195L20.034 5.88354H20.5324L22.5833 1.10143H21.5795L20.2971 4.23324Z" fill="#201D1C"/>
  <path d="M23 5.76367H25.6261V4.97435H23.9252V3.7158H25.5633V2.92603H23.9252V1.89115H25.6261V1.10119H23V5.76367Z" fill="#201D1C"/>
  <path d="M27.4337 3.24798H27.1632V1.83583H27.4483C28.025 1.83583 28.3385 2.07312 28.3385 2.52689C28.3385 2.99563 28.025 3.24798 27.4337 3.24798ZM29.2914 2.47792C29.2914 1.60504 28.6792 1.10143 27.6113 1.10143H26.2383V5.76392H27.1632V3.89093H27.2839L28.5656 5.76392H29.7044L28.21 3.79967C28.9074 3.66041 29.2914 3.19183 29.2914 2.47792Z" fill="#201D1C"/>
  <path d="M29.7102 1.33304H29.6934V1.22593H29.7112C29.7605 1.22593 29.786 1.24335 29.786 1.27863C29.786 1.3149 29.7602 1.33304 29.7102 1.33304ZM29.8857 1.27713C29.8857 1.19541 29.8284 1.15073 29.7277 1.15073H29.5938V1.56055H29.6934V1.4016L29.8102 1.56055H29.9317L29.7945 1.39155C29.8532 1.37602 29.8857 1.33402 29.8857 1.27713Z" fill="#201D1C"/>
  <path d="M29.7449 1.64806C29.5855 1.64806 29.4551 1.51773 29.4551 1.35513C29.4551 1.19194 29.5838 1.06167 29.7449 1.06167C29.9036 1.06167 30.033 1.19492 30.033 1.35513C30.033 1.51636 29.9036 1.64806 29.7449 1.64806ZM29.7463 0.997353C29.5436 0.997353 29.3828 1.15619 29.3828 1.35461C29.3828 1.5529 29.5455 1.71191 29.7463 1.71191C29.9437 1.71191 30.1054 1.55119 30.1054 1.35461C30.1054 1.15899 29.9437 0.997353 29.7463 0.997353Z" fill="#201D1C"/>
  <ellipse cx="15.7904" cy="3.47986" rx="2.48572" ry="2.48571" fill="#E77C22"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="30" height="4.96063" fill="white" transform="translate(0.105469 0.993408)"/>
  </clipPath>
  </defs>
  </svg>
}

function DHL() {
  return <svg width="30" height="8" viewBox="0 0 30 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
  <path d="M30 0.612305V7.6123H0V0.612305H30Z" fill="#FECC00"/>
  <path d="M9.67266 3.53718C9.54156 3.72487 9.32221 4.05118 9.18873 4.24176C9.12096 4.33868 8.99857 4.51464 9.40432 4.51464C9.83362 4.51464 11.5406 4.51464 11.5406 4.51464C11.5406 4.51464 11.8849 4.01941 12.1736 3.60522C12.5662 3.04177 12.2075 1.86938 10.8039 1.86938C9.54753 1.86938 5.27596 1.86938 5.27596 1.86938L4.31749 3.24625C4.31749 3.24625 9.28636 3.24625 9.54139 3.24625C9.80512 3.24625 9.80153 3.35237 9.67266 3.53718ZM8.10325 4.8543C7.6975 4.8543 7.81989 4.67798 7.88766 4.58106C8.02114 4.39047 8.24408 4.0676 8.37517 3.8799C8.50422 3.69509 8.50764 3.58897 8.24356 3.58897C7.98871 3.58897 5.85446 3.58897 5.85446 3.58897L3.92949 6.35497C3.92949 6.35497 7.36754 6.35497 8.62388 6.35497C10.1743 6.35497 11.0376 5.23998 11.3037 4.85448C11.3037 4.8543 8.53239 4.8543 8.10325 4.8543ZM11.1571 6.35497H13.9109L14.9549 4.85412L12.2014 4.85448C12.2005 4.8543 11.1571 6.35497 11.1571 6.35497ZM18.2624 1.86938L17.2061 3.3863H15.9771L17.0329 1.86938H14.2798L12.438 4.51464H19.1738L21.0151 1.86938H18.2624ZM15.14 6.35497H17.8928L18.9373 4.85448H16.1845C16.1835 4.8543 15.14 6.35497 15.14 6.35497ZM0 5.44627V5.76499H3.83526L4.05683 5.44627H0ZM4.46907 4.8543H0V5.17266H4.24699L4.46907 4.8543ZM0 6.35497H3.42439L3.64493 6.03805H0V6.35497ZM25.9585 5.76481H30V5.44609H26.1804L25.9585 5.76481ZM25.548 6.35497H30V6.03805H25.7684L25.548 6.35497ZM26.592 4.8543L26.3706 5.17303H30V4.8543H26.592ZM22.9862 4.51464L24.8278 1.86938H21.9121C21.9111 1.86938 20.0691 4.51464 20.0691 4.51464H22.9862ZM19.833 4.8543C19.833 4.8543 19.6317 5.14505 19.5339 5.2851C19.1883 5.77889 19.494 6.35497 20.6223 6.35497C21.9258 6.35497 25.0436 6.35497 25.0436 6.35497L26.0881 4.85448H19.833V4.8543Z" fill="#D50029"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="30" height="7" fill="white" transform="translate(0 0.612305)"/>
  </clipPath>
  </defs>
  </svg>
  
}

const bgOrangeGradient = "bg-gradient-to-b from-primary-dark to-primary-light";

const ListItem = ({ classnames = "", iconSrc, text }) => (
  <li
    className={`flex mx-0 lg:mx-6 xl:mx-6 2xl:mx-6 first:ml-0 last:mr-0 my-2 font-primary text-base lg:text-lg md:text-sm xl:text-sm font-semibold text-gray-900 items-center ${classnames}`}
  >
    <img className="mr-1.5" src={iconSrc} alt="" /> {text}
  </li>
);

const FooterBottomListItem = ({ classnames = "", href, onClick, text }) => (
  <li
    className={`flex my-1 lg:my-2 font-primary text-sm lg:text-base font-semibold lg:font-medium text-gray-400 hover:text-black cursor-pointer ${classnames}`}
  >
    {onClick ? (
      <div onClick={onClick}>{text}</div>
    ) : (
      <a href={href}>
        <a>{text}</a>
      </a>
    )}
  </li>
);

const useStyles = makeStyles({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    "&.MuiAccordionSummary-expandIcon": {},
  },
  summaryRoot: {
    padding: 0,
    maxHeight: "40px",
    minHeight: "40px",
    "&.Mui-expanded": { maxHeight: "40px", minHeight: "40px" },
  },
  expanded: {
    margin: 0,
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    marginRight: 0,
    "&.Mui-expanded": {
      transform: "none",
    },
  },
});

const ListWithHeading = ({
  headingText,
  expandedInitialState = false,
  isCollapsible = false,
  listItems = [],
  blockStyles = "",
  headingStyles = "",
}) => {
  const [isExpanded, setExpanded] = useState(expandedInitialState);
  const classes = useStyles();

  return (
    <Accordion
      TransitionProps={{ mountOnEnter: true }}
      classes={{ root: classes.accordionRoot }}
      className={`flex flex-col mx-0 my-0 lg:mx-10 first:ml-0 last:mr-0 bg-transparent shadow-none z-0 ${blockStyles}`}
      elevation={0}
      expanded={isCollapsible ? isExpanded : true}
      onChange={() => setExpanded(!isExpanded)}
      defaultExpanded={expandedInitialState}
    >
      <AccordionSummary
        className="cursor-auto"
        classes={{
          root: classes.summaryRoot,
          content: classes.content,
          expanded: classes.expanded,
          expandIcon: classes.expandIcon,
        }}
        expandIcon={
          isCollapsible ? (
            <b className="w-4 leading-[14px] text-gray-800 font-light">
              {isExpanded ? "-" : "+"}
            </b>
          ) : null
        }
      >
        <b
          className={`block mt-2 font-semibold font-primary text-gray-900 text-base lg:text-sm ${headingStyles}`}
        >
          {headingText}
        </b>
      </AccordionSummary>

      <ul>
        {listItems?.map(({ href, onClick, text }) => (
          <FooterBottomListItem
            key={text}
            href={href}
            onClick={onClick}
            text={text}
          />
        ))}
      </ul>
    </Accordion>
  );
};

const PaymentMethod = ({ classnames, children, imgSrc = "" }) => (
  <div
    className={`flex justify-center items-center w-9 h-[26px] bg-gray-100 ${classnames}`}
  >
    {children}
  </div>
);

const Footera = ({ classnames = "", href, text }) => (
  <a href={href}>
    <a
      className={`font-primary text-gray-400 text-[9px] lg:text-sm ml-0 lg:ml-10 ${classnames}`}
    >
      {text}
    </a>
  </a>
);

const Subscription = () => {
  // const {
  //   email,
  //   handleInputChange,
  //   handleSubscribeClick,
  //   isSuccessMessageShown,
  // } = useSendGridSubscription();

  const inputStyles =  { boxShadow: "0 0 0 2px transparent" };

  return (
    <div>
      <div className="mt-5 font-primary text-xs lg:text-base	text-gray-400">
        Subscribe to our newsletter
      </div>
      <div className="relative">
        <MainPageInput
          classnames="mt-2 bg-gray-100 text-gray-400 pr-10"
          placeholder={"Your email"}
          // onChange={handleInputChange}
          style={inputStyles}
        />
        {/* {isSuccessMessageShown && (
          <div className="text-primary text-sm">
            You have been subscribed to newsletter
          </div>
        )} */}
        <div
          // onClick={handleSubscribeClick}
          className="absolute flex justify-center items-center w-[30px] h-[30px] bg-gray-300 rounded-full top-[15px] right-1.5 cursor-pointer hover:bg-gray-400"
        >
          <Arrow />
        </div>
      </div>
    </div>
  );
};

const MyProfileButton = () => (
  <a href="/profile">
    <a
      className={`block lg:hidden mt-4 flex justify-center items-center h-14 font-primary text-base text-white ${bgOrangeGradient}`}
    >
      <img loading="lazy" className="mr-2" src="/img/main/user.svg" alt="" />
      <span>My profile</span>
    </a>
  </a>
);

const Sociala = ({ classnames = "", href, imgSrcUrl }) => (
  <a
    className={`flex justify-center items-center block w-8 h-8 border border-gray-400 border-opacity-40 border-solid rounded-full hover:border-primary ${classnames}`}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <img src={imgSrcUrl} width="14px" height="14px" alt="Social network logo" />
  </a>
);

const SocialNetworksas = () => (
  <div className="flex justify-between items-center w-20 mt-4 mx-auto lg:hidden">
    {[
      {
        href: "https://www.instagram.com/ohmybrand_official/",
        imgSrcUrl: "/img/main/insta.svg",
      },
      {
        href: "https://www.facebook.com/ombstore/",
        imgSrcUrl:
          "/img/main/facebook.svg",
      },
    ].map(({ href, imgSrcUrl }) => (
      <Sociala key={href} href={href} imgSrcUrl={imgSrcUrl} />
    ))}
  </div>
);

const Footer = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const iconBaseUrl = "/img/main/footer/";

  return (
    <>
      <Logo classnames="flex justify-center mt-12 lg:hidden" />
      <SocialNetworksas />
      <MyProfileButton />
      <section className="relative p-5 pb-32 lg:pb-16 bg-gray-50 mt-5">
        <div className="">
          <div className="">
            <ul className="flex flex-col lg:flex-row w-full justify-center items-start lg:items-center">
              {[
                {
                  text: "Free worldwide express shipping",
                  iconSrc: iconBaseUrl + "airplanetakeoff.svg",
                },
                {
                  text: "Preview mode",
                  iconSrc: iconBaseUrl + "eye.svg",
                },
                {
                  text: "Up to 4 business days production",
                  iconSrc: iconBaseUrl + "clock.svg",
                },
                {
                  text: "100% safe & secure checkout",
                  iconSrc: iconBaseUrl + "shield.svg",
                },
              ].map(({ iconSrc, text }, index) => (
                <ListItem
                  key={index}
                  classnames={clsx(index, "tracking-tight")}
                  iconSrc={iconSrc}
                  text={text}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-0 lg:mt-8 flex flex-col lg:flex-row justify-between px-0 lg:px-44 2xl:px-56">
          <div className="flex flex-col lg:flex-row justify-center">
            <ListWithHeading
              headingText={"SHOP"}
              expandedInitialState
              isCollapsible={isMobile}
              listItems={[
                { href: "/collections", text: "Collections" },
                { href: "/catalog", text: "Catalog" },
              ]}
            />
            <ListWithHeading
              headingText={"HELP CENTER"}
              isCollapsible={isMobile}
              listItems={[
                { href: "/faq", text: "FAQ" },
                { href: "/howItWorks", text: "How it works" },
                // { onClick: () => show(), text: "Contact us" },
              ]}
            />
            {/* <ListWithHeading
              headingText={"MY ACCOUNT"}
              headingStyles="text-primary"
              blockStyles="hidden lg:block"
              listItems={[
                {
                  onClick: () => setModal("login"),
                  text: "Login",
                },
                {
                  onClick: () => setModal("register"),
                  text: "Sign Up",
                },
              ]}
            /> */}
            <ListWithHeading
              headingText={"FOLLOW US"}
              isCollapsible={isMobile}
              listItems={[
                {
                  href: "https://www.facebook.com/ombstore/",
                  text: "Facebook",
                },
                {
                  href: "https://www.instagram.com/ohmybrand_official/",
                  text: "Instagram",
                },
              ]}
            />
          </div>
          <Subscription />
        </div>

        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className="flex justify-center  items-center mt-5 mb-6">
            {[Paypal, Visa, Maestro, Discover, DHL].map((icon, index) => (
              <PaymentMethod key={index} classnames={index ? "m-1" : ""}>
                {React.createElement(icon, {
                  className: "w-auto h-auto lg:w-6 lg:h-6",
                })}
              </PaymentMethod>
            ))}
          </div>
          <div className="flex justify-center lg:justify-around items-center mb-1 flex-wrap">
            {[
              { href: "/privacy", text: "Terms & Conditions" },
              { href: "/privacy", text: "Privacy Police" },
              { href: "/privacy", text: "Cookie Police" },
              { href: "/impressum", text: "Impressum" },
            ].map(({ href, text }, index) => {
              return (
                <Footera
                  key={index}
                  href={href}
                  text={text}
                  classnames={"w-1/2 lg:w-auto text-center mb-2"}
                />
              );
            })}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row text-gray-400 justify-center items-center">
          <p className="text-center">Croatia, 42 000 VARAZDIN, Ulica Ivana Sokaca 6</p>
        </div>

        <div className="absolute bottom-0 left-0 w-full h-12 pt-4 bg-gray-100 text-[10px] lg:text-base text-gray-400 text-center align-top pb-16 lg:pb-0">
        © 2021 OH! MY BRAND | All Rights Reserved. | #Beomb
        </div>
      </section>
    </>
  );
};

export default Footer;
