import LogoIcon from './icons/logo';
const Logo = ({ classnames = "" }) => (
  <a href="/">
    <div className={`inline-block align-middle ${classnames}`}>
      <LogoIcon className="text-primary h-9 w-52"/>
    </div>
  </a>
);

export default Logo;
