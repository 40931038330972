function Logo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 210 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M66.676 17.034c0 4.301-3.476 7.788-7.764 7.788s-7.764-3.487-7.764-7.788 3.476-7.788 7.764-7.788 7.764 3.487 7.764 7.788zm-12.072 0a4.315 4.315 0 004.308 4.322 4.315 4.315 0 004.308-4.322 4.315 4.315 0 00-4.308-4.322 4.315 4.315 0 00-4.308 4.322zM78.077 24.252v-5.869h-5.833v6.095h-3.141V9.353h3.14v5.87h5.834v-5.87h3.14v14.9h-3.14zM87.949 9.353v.451l-.898 9.482h-1.795l-.897-9.482v-.451h3.59zm-3.366 14.9v-3.387h3.366v3.386h-3.366zM108.365 24.252v-9.707l-4.038 6.321-4.039-6.32v9.706h-3.14V9.353h3.365l3.814 6.321 3.814-6.321h3.365v14.9h-3.141zM122.051 
18.383v5.87h-3.141v-5.87l-5.384-9.03h3.589l3.366 6.095 3.365-6.095h3.59l-5.385 9.03zM146.803 22.093c-.224.451-1.586.445-2.034.896-.449.452.456.458-.457.916-.673.226-1.395.347-2.293.347h-6.73V9.353h6.506c1.346 0 2.692.451 3.365 1.129.898.677 1.347 1.58 1.347 2.709 0 .451 0 .903-.225 1.128-.224.226-.224.678-.449.903l-.673.678c-.224.225-.448.451-.673.451.898.226 1.571.678 2.02 1.355.448.677.673 1.16.673 2.257 0 1.535-.152 1.678-.377 2.13zm-3.213-8.225c0-.452-.225-.903-.449-1.129-.449-.451-.897-.451-1.57-.451h-3.141v3.16h2.916c.673 0 1.346-.226 1.571-.451.448-.226.673-.678.673-1.129zm.897 5.87c0-.452-.224-.904-.448-1.13-.449-.225-.898-.45-1.795-.45h-3.59v3.16h3.814c.673 0 1.122-.226 1.571-.452.224-.226.448-.451.448-1.129zM158.846 24.252l-3.141-4.74h-2.468v4.74h-3.141V9.353h6.507c1.794 0 2.916.451 4.038 1.354.898.903 1.346 2.032 1.346 3.612 0 1.13-.224 2.258-.897 2.935s-1.346 1.355-2.244 1.58l3.366 5.418h-3.366zm0-9.707c0-.677-.224-1.129-.673-1.58-.449-.451-1.122-.677-1.795-.677h-3.141v4.289h3.141c.673 0 1.346-.226 1.795-.677.449-.226.673-.678.673-1.355zM175.449 24.253l-1.347-3.387h-6.057l-1.346 3.387h-3.141l6.057-15.126h2.917l6.058 15.126h-3.141zm-4.488-11.062l-1.794 4.74h3.814l-2.02-4.74zM190.705 24.252l-6.731-9.481v9.481h-3.141V9.353h2.917l6.731 9.256V9.353h3.141v14.9h-2.917zM209.327 19.737c-.449.903-.898 1.806-1.571 2.258-.673.677-1.346 1.354-2.243 1.58-.898.452-2.02.677-3.141.677h-5.609V9.353h5.609c1.121 0 2.019.226 3.141.677.897.452 1.795.903 2.468 1.58.673.678 1.121 1.355 1.57 
2.258.449.903.449 1.806.449 2.935 0 1.128-.224 2.031-.673 2.934zm-2.692-2.934c0-.678 0-1.355-.225-1.806-.224-.452-.449-1.129-.897-1.355-.449-.226-.898-.677-1.346-.903-.449-.226-1.122-.451-1.795-.451h-2.468v9.03h2.468c.673 0 1.121 0 1.795-.226.448-.226.897-.452 1.346-.903.448-.452.673-.903.897-1.355.225-.677.225-1.354.225-2.031z"
        fill="#252426"
      />
      <path
        d="M144.312 16.576c2.557-.367 2.436-3.36 1.827-5.04-.305.153-1.37.092-1.37.459v1.374c0 .367-.305 1.069-.457 1.374-1.066.764-2.557 2.2 0 1.833zM147.052 21.065c0 2.2-3.045 3.054-4.567 3.207l-.314-.472a.77.77 0 01-.143.014c-.365 0-.456-.611-.456-.916l.599.902c.409-.077.818-.444 1.228-.444.456 0 .456-1.375 1.826-2.749 1.096-1.1 1.37-1.985 1.37-2.29.153 0 .457.55.457 2.748z"
        fill="#252426"
      />
      <path
        d="M21.741 13.19c-.911 0-1.822-.221-2.506-.885l-5.24-3.982c-.456-.442-1.139 0-1.139.664v14.158c0 .221 0 .221.228.221l8.43 4.425c.227.22.455.22.91 0l8.203-4.425.227-.221V8.987c0-.664-.91-1.106-1.367-.664l-5.012 3.982a4.466 4.466 0 01-2.734.885zm5.924-.664c.228-.22.683 0 .683.443v8.849c0 .22 0 .22-.228.22l-5.923 3.32c-.228.22-.456.22-.911 0l-6.152-3.32-.228-.22v-8.85c0-.442.456-.663.684-.442l2.05 1.549c2.279 1.77 5.24 1.77 7.519 0l2.506-1.549z"
        fill="currentColor"
      />
      <path
        d="M41.206 22.802l2.504-9.915c0-.231 0-.462-.227-.692L36.198 1.588c-.228-.461-.683-.692-1.366-.692L23.449 2.51h-3.187L8.65.896c-.455 0-.91.23-1.138.692L.228 12.195c-.228.23-.228.46-.228.691l2.504 9.916c0 .23.228.23.228.46h.455l4.098-1.613h.455c.228 0 .228.23.228.46v3.46c0 .23 0 .23.228.46L21.4 33.41c.228.23.455.23.683 0l13.204-7.38c.228 0 .228-.23.228-.46V22.34c0-.23 0-.23.227-.46.456-.231.456-.231.683-.231l3.87 1.383h.456c.227 0 .455-.23.455-.23zm-1.821-2.998s0 .23-.228.23h-.227l-2.96-.922c-.228 0-.455-.23-.455-.46V14.27c0-.922-.91-1.845-1.822-1.845h-.227c-.228 0-.456.23-.456.461v11.53c0 .23 0 .461-.227.461L22.31 30.872c-.227 0-.227.23-.455.23-.227 0-.227 0-.455-.23l-10.928-5.995c-.227 0-.227-.23-.227-.461v-11.53c0-.23-.228-.46-.456-.46-1.138 0-1.821.922-1.821 1.844v4.381c0 .23-.228.461-.455.461L4.78 20.265h-.228l-.227-.23-1.822-6.687c0-.23 0-.462.228-.692L8.65 4.124c.228-.46.683-.922 1.366-.691l10.245 1.614h3.187l10.244-1.614c.456 0 1.139.23 1.366.691l5.92 8.532c.227.23.227.461.227.692l-1.821 6.456z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Logo;
