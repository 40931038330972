function MobileMenu({ props }) {
  return (
    <div
      className={`mobile__menu ${props.menuOpened ? 'open' : ''}`}
    >
      <li
        onClick={() => {
          props.menuCaller(!props.menuOpened);
        }}
        className="mobile__menu__decaller"
      >
        <img
          src={
            "/img/header/cart__back.svg"
          }
          alt=""
        />
      </li>
      <h4>MENU</h4>
      <ul>
        <li onClick={props.menuCaller}>
          <a href="/catalog">
            <a>SHOP</a>
          </a>
        </li>
        <li onClick={props.menuCaller}>
          <a href="/stickers">
            <a>EMOJI STICKERS</a>
          </a>
        </li>
        <li onClick={props.menuCaller}>
          <a href="/howItWorks">
            <a>HOW IT WORKS</a>
          </a>
        </li>
        <li onClick={props.menuCaller}>
          <a href="/faq">
            <a>FAQ</a>
          </a>
        </li>
        <li onClick={props.menuCaller}>
          <a href="/collections">
            <a>COLLECTIONS</a>
          </a>
        </li>
      </ul>

      <div className="collections">
        {props.menuOpened && (
          <>
            <a href="/catalog?item_collection=winter">
              <a>
                <div onClick={props.menuCaller} className="collection winter">
                  <p className="title">WINTER COLLECTION</p>
                </div>
              </a>
            </a>
            <a href="/catalog?item_collection=summer">
              <a>
                <div onClick={props.menuCaller} className="collection summer">
                  <p className="title">SUMMER COLLECTION</p>
                </div>
              </a>
            </a>
            <a href="/catalog?gender=woman">
              <a>
                <div onClick={props.menuCaller} className="collection women">
                  <p className="title">WOMEN</p>
                </div>
              </a>
            </a>
            <a href="/catalog?gender=man">
              <a>
                <div onClick={props.menuCaller} className="collection men">
                  <p className="title">MAN</p>
                </div>
              </a>
            </a>
          </>
        )}
      </div>
    </div>
  );
}
export default MobileMenu;
