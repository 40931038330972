import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import styles from './index.module.sass'
import { List, ListOptions } from '../List'

export class Slider extends List {
  get optionsClass() { return SliderOptions }
  get RootComp() { return Swiper }

  get swiper() {
    return this.ref.current.swiper
  }

  renderItem(datum) {
    return <SwiperSlide>
      { super.renderItem(datum) }
    </SwiperSlide>
  }
}

class SliderOptions extends ListOptions {
  prepareProps(props) {
    props = super.prepareProps(props)
    props.className ||= []
    props.className.push(styles.root)
    return props
  }
}
