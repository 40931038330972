import { Component, createRef } from 'react'
import styles from './index.module.sass'
import guarantees from './guarantees.yaml'
import { List } from '../_shared'

let asset = name => `/rnd/guarantees/${name}`

export class Guarantees extends Component {
  constructor(props) {
    super(props)
    this.video = createRef()
    this.state = {
      success: null,
      error: null,
    }
  }

  // componentDidMount() {
  //   this.video.current.play()
  //     .then(console.log)
  //     .catch(console.error)
  // }

  render() {
    return <section id={styles.section}>
      <div id={styles.ad}>
        <video preload='true' playsInline autoPlay loop muted ref={this.video}>
          <source src={asset`new-collection.webm`} type='video/webm' />
          <source src={asset`new-collection.mp4`} type='video/mp4' />
        </video>
        <a id={styles.button} href='/catalog'>Explore now</a>
      </div>
      <List id={styles.guarantees}
        itemComp={Guarantee}
        items={guarantees}
        keyName='svg'
      />
      <hr />
    </section>
  }
}

function Guarantee({ svg, header, text }) {
  return <div className={styles.guarantee}>
    <img src={asset(svg)} className={styles.svg} />
    <h5 className={styles.header}>{header}</h5>
    <span className={styles.text}>{text}</span>
  </div>
}
