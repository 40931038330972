import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar';
import Router from './components/Router';

function App() {
  return (
    <>
      <Navbar />    
      <Router />
      <Footer />
    </>
  )
}

export default App;
