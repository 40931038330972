import { Component, createRef } from 'react'
import styles from './index.module.sass'
import { Slider, Wave } from '../_shared'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css/pagination'
import heroes from './heroes.yaml'

let asset = name => `/rnd/heroes/${name}`

export class Heroes extends Component {
  constructor(props) {
    super(props)  
    this.slider = createRef()
    this.state = {
      showOverlay: heroes[0].overlay,
    }
  }

  get hasCurrent() {
    return !!this.slider.current
  }

  get currentIndex() {
    if (!this.hasCurrent)
      return 0
    let activeIndex = this.slider.current.swiper.activeIndex - 1
    return activeIndex < heroes.length && activeIndex >= 0
      ? this.slider.current.swiper.activeIndex - 1
      : 0
  }

  get currentHeroData() {
    if (this.hasCurrent)
      return heroes[this.currentIndex]
  }

  get shouldCurrentlyShowOverlay() {
    if (this.hasCurrent)
      return this.currentHeroData.overlay
    else
      return this.state.showOverlay
  }

  render() {
    return <section id={styles.section}>
              <div id={styles.title} className={this.state.showOverlay ? styles.overlay : ''}>
                <h1>
                  Custom PJ's with your Pet Character
                  <span>.</span>
                </h1>
                <span>
                  Your pet is now your brand
                </span>
              </div>
              <Slider id={styles.heroes} ref={this.slider}
                itemComp={Hero}
                items={heroes}
                {...this.sliderOptions}
              />
              <Wave />
              <div id={styles.bullets} />
              <a id={styles.button} href='/catalog'>Let's get started</a>
            </section>
  }

  sliderOptions = {
    modules: [
      Autoplay,
      Pagination,
    ],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: `#${styles.bullets}`,
      type: 'bullets',
    },
    loop: true,
    onSlideChange: () =>
      this.setState({showOverlay: this.shouldCurrentlyShowOverlay}),
  }
}

function Hero({img, overlay}) {
  let classes = [styles.hero]
  if (overlay) classes.push(styles.overlay)
  return <img src={asset(img)} className={classes.join(' ')} />
}
