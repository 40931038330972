import {
  Heroes, SubHero, Catalog,
  NewArrivals, Guarantees,
  DesignShowcase, HowItWorks,
  Reviews, SavingPets
} from '.'
import styles from '../OMB.module.sass'

function Main() {
  return (
  <main id={styles.main}>
    <Heroes />
    <SubHero />
    <Catalog />
    <NewArrivals />
    <Guarantees />
    <DesignShowcase />
    <HowItWorks />
    <Reviews />
    <SavingPets />
  </main>
  )
}

export default Main