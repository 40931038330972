import styles from './index.module.sass'

export function Counter({ digits, ...props }) {
  return <div className={styles.counter} {...props}>{
    digits.map(digit =>
      digit === null
        ? <span className={styles.spacer} />
        : <span className={styles.digitBackground}>
            <span className={styles.digit}>
              {digit}
            </span>
          </span>
    )
  }</div>
}
