import { Component, createRef } from 'react'
import { findDOMNode } from 'react-dom'

export class List extends Component {
  get optionsClass() { return ListOptions }
  get RootComp() { return 'div' }

  constructor(props) {
    super(props)
    this.options = new this.optionsClass(props)
    this.ref = createRef()
  }

  componentDidMount() {
    this.element = findDOMNode(this)
    this.addIdFromProps()
  }
  
  addIdFromProps() {
    if (this.props.id)
      this.element.id = this.props.id
  }

  render() {
    let opts = this.options
    return <this.RootComp ref={this.ref} {...opts.props}>{
      opts.items
        .map(datum => opts.prepareDatum(datum))
        .map(datum => this.renderItem(datum))
    }</this.RootComp>
  }
  
  renderItem(datum) {
    let opts = this.options
    return <opts.ItemComp
      key={datum[opts.keyName]}
      {...datum}
    />
  }
}

export class ListOptions {
  constructor(props) {
    let { itemComp, items, ...options } = props
    this.ItemComp = itemComp
    this.items = items
    this.rawOptions = options
  }

  get keyName() {
    let keyName = this.rawOptions.keyName
    return keyName ? keyName : 'datum'
  }

  get props() {
    let { ...props } = this.rawOptions
    return this.prepareProps(props)
  }

  prepareProps(props) {
    delete props['id']
    delete props['keyName']
    return props
  }

  prepareDatum(datum) {
    return isSimpleObject(datum)
      ? datum
      : { datum }
  }
}

function isSimpleObject(obj) {
  return obj === Object(obj)
}
