import { Component } from 'react'
import styles from './index.module.sass'
import { Slider } from '../_shared'
import categories from './categories.yaml'
import catalogs from './catalogs.yaml'

let asset = name => `/rnd/catalog/${name}`,
  categoryAsset = name => `${asset`categories`}/${name}`,
  catalogAsset = name => `${asset`catalogs`}/${name}`

export class Catalog extends Component {
  render() {
    return <section id={styles.section}>
      <div id={styles.titles}>
        <h3>Catalog</h3>
        <p><b>Swipe right</b> to see more</p>
      </div>
      <Slider id={styles.categorySlider}
        itemComp={CategorySlide}
        items={[...categories, ...categories]}
        keyName='name'
        {...this.categorySliderOptions}
      />
      <Slider id={styles.catalogSlider}
        itemComp={CatalogSlide}
        items={catalogs}
        keyName='name'
        {...this.catalogSliderOptions}
      />
    </section>
  }
  categorySliderOptions = {
    slidesPerView: 3.75,
    loop: true,
  }

  catalogSliderOptions = {
    slidesPerView: 1.05,
    loop: true,
  }
}

function CategorySlide({ image, name, color, url }) {
  let colorStyle = {}
  if (color) colorStyle.borderColor = colorStyle.backgroundColor = color
  return <div className={styles.slide}>
    <a href={"/catalog"}>
      <img src={categoryAsset(image)} style={colorStyle} /> 
    </a>
    <span>{name}</span>
  </div>
}

function CatalogSlide({ image, name, discount, url }) {
  return <div className={styles.slide}>
    <a href={"/catalog"}>
      <img src={catalogAsset(image)}/>
    </a>
    <span className={styles.name}>{name}</span>
    { discount && 
      <span className={styles.discount}>
        {`Up to ${discount}% off`}
      </span>
    }
  </div> 
}
