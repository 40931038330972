import { Component } from 'react'
import styles from './index.module.sass'
import { Counter } from '../_shared'

let asset = name => `/rnd/saving-pets/${name}`

export class SavingPets extends Component {
  render() {
    return <section id={styles.section}>
      <h3>Saving pets together.</h3>
      <img src={asset`world.jpg`}/>
      <p>With each purchase, we will increase awareness of animal adoption. 
      And we will donate money to save animals around the world</p>
      <Counter id={styles.counter} digits={this.counterDigits} />
    </section>
  }

  counterDigits = [
    5, 8, null,
    5, 8, 4, null,
    8, 3, 3,
  ]
}

