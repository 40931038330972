import { useState, useEffect, memo } from "react";
import { useMediaQuery } from "react-responsive";
import MobileMenu from "./MobileMenu";
import Logo from "./Logo";

const Navbar = memo(function Navbar() {
  const [sticky, setSticky] = useState(false);

  const [searchModal, setSearchModal] = useState(false);
  const [Evg, toggleEvg] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [isActive, setActive] = useState(false);
  const [menuOpened, menuOpen] = useState(false);

  const handleClose = () => {
    toggleEvg(!Evg);
    setActive(!isActive);
  };

  const menuCaller = () => {
    menuOpen(!menuOpened);
  };

  useEffect(() => {
    if (!isMobile && !sticky) return;

    const el =
      document.querySelector(".intercom-lightweight-app") ||
      document.querySelector(".intercom-app");
    if (el && sticky) {
      el.firstChild.style.transition = "0.4s";
      el.firstChild.style.bottom = "80px";
    } else if (el && !sticky) {
      el.firstChild.style.bottom = "20px";
    }
  }, [isMobile, sticky]);


  return (
    <>
      <header style={{backgroundColor: '#edeef3'}}>
        <div className="menu__panel">
          <div className="wrapper__content">
            <div className="logo-desktop">
              <Logo classnames="cursor-pointer" />
            </div>
            <div className="menu">
              <ul>
                <li>
                  <a href="/catalog">
                    SHOP
                  </a>
                </li>
                  <>
                    <li>
                      <a href="/stickers">
                        EMOJI STICKERS
                      </a>
                    </li>
                    <li>
                      <a href="/howItWorks">
                        HOW IT WORKS
                      </a>
                    </li>
                  </>
                <li>
                  <a href="/collections">
                    COLLECTIONS
                  </a>
                </li>
              </ul>
            </div>
            <div className="actions__block w-full lg:w-auto flex lg:inline-block">
              <ul className="flex flex-grow lg:inline-block">
                <li
                  onClick={(e) => {
                    menuCaller(e);
                  }}
                  className="mobile__menu__caller"
                >
                  <img src="/img/header/mobile__menu.svg" alt="" />
                </li>
                <li className="mr-auto ml-auto flex-grow justify-center logo-mobile">
                  <Logo classnames="cursor-pointer" />
                </li>
                {/* <li>
                  <CartButton />
                </li> */}
                {/* <li>
                  <Avatar />
                </li> */}
              </ul>
            </div>

            <div className="mobile__actions">
              <ul>
                {/* <li>
                  <CartButton />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu props={{ menuCaller, isMobile, menuOpened }} />
    </>
  );
});

export default Navbar;
