import { Component } from 'react'
import styles from './index.module.sass'
import { Slider } from '../_shared'
import reviews from './reviews.yaml'
import { Autoplay } from 'swiper'

let asset = name => `/rnd/reviews/${name}`

export class Reviews extends Component {
  render() {
    return <section id={styles.section}>
      <div id={styles.text}>
        <h3>Reviews that made our day</h3>
        <p id={styles.tiktok}>We love seeing your beautiful stories, reels and Tiktoks!</p>
      </div>
      <Slider id={styles.reviews}
        itemComp={Review}
        items={reviews} 
        keyName='image'
        {...this.sliderOptions}
      />
      <p id={styles.swipe}><b>Swipe left-right</b> to see more</p>
    </section>
  }

  sliderOptions = {
    modules: [Autoplay],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 2,
    centeredSlides: true,
    loop: true,
  }
}

function Review({ image, brand }) {
  return <div className={styles.review}>
    <img className={styles.image} src={asset(image)} />
    <img className={styles.brand} src={asset(brand)} />
  </div>
}
