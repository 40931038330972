import { Component, createRef } from 'react'
import styles from './index.module.sass'
import { List, Slider, Wave } from '../_shared'
// import { Thumbs } from 'swiper'
import designs from './designs.yaml'
import likes from './likes.yaml'
import  guarentees  from './guarentees.yaml'
import 'swiper/css/thumbs'

let asset = name => `/rnd/design-showcase/${name}`

export class DesignShowcase extends Component {
  constructor(props) {
    super(props)
    this.mainSlider = createRef()
    this.thumbSlider = createRef()
    this.backgroundThumbSlider = createRef()
    this.guarenteesSlider = createRef()
    this.state = {
      mainSwiper: null,
      thumbsSwiper: null,
      backgroundThumbsSwiper: null,
    }
  }

  render() {
    return <section id={styles.section}>
      <div id={styles.titles}>
        <h4>Custom brand for every pet</h4>
        <p>Based on your selections we will create PJ’s &amp;&nbsp; products matching with your pet cartoon.</p>
      </div>
      <div id={styles.showcase}>
        <Slider id={styles.slider} ref={this.mainSlider}
          itemComp={Showcase}
          items={designs}
          keyName='case'
          {...this.mainSliderOptions}
        />
        <div id={styles.thumbContainer}>
          <button id={styles.previous} onClick={() => this.slidePrev()}>
            <img src={asset('icon-previous.svg')} />
          </button>
          <div id={styles.sliderContainer}>
            <Slider id={styles.thumbSlider} ref={this.thumbSlider}
              itemComp={ThumbSlide}
              items={designs}
              keyName='product'
              {...this.thumbSliderOptions}
            />
            <Slider id={styles.backgroundThumbSlider} ref={this.backgroundThumbSlider}
              itemComp={ThumbSlide}
              items={designs}
              keyName='product'
              {...this.backgroundThumbSliderOptions}
          />
          </div>
          <button id={styles.next} onClick={() => this.slideNext()}>
            <img src={asset('icon-next.svg')} />
          </button>
        </div>
        <div id={styles.likes}>            
          <List id={styles.likeList}
            itemComp={Like}
            items={likes}
          />
          <p id={styles.likeTitle}><b>Mike Peter</b> and <b>12 others </b>
          like this outfit</p>
        </div>
        <Wave id={styles.wave} />
        <Slider id={styles.guarenteesSlider} ref={this.guarenteesSlider}
            itemComp={Guarentees}
            items={guarentees}
            keyName='guarentee'
            {...this.guarenteesSliderOptions}/>
      </div>
    </section>
  }

  slidePrev() {
    this.state.mainSwiper.slidePrev()
    this.state.thumbsSwiper.slidePrev()
    this.state.backgroundThumbsSwiper.slidePrev()
  }

  slideNext() {
    this.state.mainSwiper.slideNext()
    this.state.thumbsSwiper.slideNext()
    this.state.backgroundThumbsSwiper.slideNext()
  }

  get mainSliderOptions() {
    return {
      allowTouchMove: false,
      multipleActiveThumbs: false,
      loop: true,
      onSwiper: mainSwiper => this.setState({ mainSwiper })
    }
  }
  
  get thumbSliderOptions() {
    return {
      allowTouchMove: false,
      loop: true,
      slidesPerView: 1,
      onSwiper: thumbsSwiper => this.setState({ thumbsSwiper })
    }
  }
  get backgroundThumbSliderOptions() {
    return {
      allowTouchMove: false,
      loop: true,
      centeredSlides: true,
      slidesPerView: 3,
      onSwiper: backgroundThumbsSwiper => this.setState({ backgroundThumbsSwiper })
    }
  }

  get guarenteesSliderOptions() {
    return {
      allowTouchMove: true,
      loop: true,
      slidesPerView: 1.2,
    }
  }
}

function Showcase({ product, brandImage, brandName }) {
  return <div className={styles.slide}>
    <img draggable="false" src={asset(product)} className={styles.img}/>
    <div className={styles.brand}>
      <img draggable="false" src={asset(brandImage)}/>
      <span>{brandName}</span>
    </div>
  </div>
}

function ThumbSlide({ brandImage }) {
  return <div className={styles.thumb}>
    <img
      draggable="false"
      src={asset(brandImage)} />
  </div>
}

function Like({ image, text }) {
  return <div className={styles.like}>
    {image && 
      <img src={asset(image)} className={styles.img} /> }
    {text && <span className={styles.text}>{text}</span> }
  </div>
}

function Guarentees({ image, header, text }) {
  return <div className={styles.guarantee}>
    {image && 
      <img src={asset(image)} className={styles.img} /> }
    <div className={styles.textBox}>
      {text && <h5 className={styles.header}>{header}</h5> }
      {text&&
      <span className={styles.text}>{text}</span> }
    </div>
  </div>
}
