export default function Maintenance({productName}) {
  let mailto = "mailto:info@omb-shop.com"
  if (productName) mailto += `?subject=Product Name - ${productName}`
  return <>
    <style jsx>{`
      #maintanace {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      #maintanace-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 20em;
        width: 80%;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        margin: 0 auto;
      }
      h1 {
        font-size: 2em;
        font-weight: 600;
        margin-block: .4em;
      }
      #tools {
        height: 4em;
        fill: #FF4D00;
      }
      h2 {
        font-weight: 700;
      }
      #contact {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-block: 1rem;
      }
      #contact > a {
        padding: 0.25em 10%;
        color: white;
        background-color: #FF4D00;
        border-radius: 1em;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.5em;
        letter-spacing: 2px;
        transition: 0.25s ease opacity;
        text-decoration: none;
      }
      #contact > a:hover {
        opacity: 80%;
      }
      #contact > a:active {
        transform: translate(0, 2px);
      }
      #in-site {
        text-transform: uppercase;
        font-weight: bold;
        color: #FF4D00;
      }
      #steps {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 1rem;
      }
      .step > div {
        width: 1em;
        height: 1em;
        font-size: 2em;
        padding: .25em;
        line-height: 1em;
        color: #FF4D00;
        box-sizing: content-box;
        border: .125em solid #FF4D00;
        border-radius: 50%;
      }
      .step > .step-text {
        font-style: italic;
      }
    `}</style>
    <section id="maintanance">
      <section id="maintanace-section">
        <h1>Our Site is Under Maintenance</h1>
        <svg id='tools' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><g><rect fill='none' height='24' width='24'/></g><g><g><rect height='8.48' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.8717 17.6255)' width='3' x='16.34' y='12.87'/><path d='M17.5,10c1.93,0,3.5-1.57,3.5-3.5c0-0.58-0.16-1.12-0.41-1.6l-2.7,2.7L16.4,6.11l2.7-2.7C18.62,3.16,18.08,3,17.5,3 C15.57,3,14,4.57,14,6.5c0,0.41,0.08,0.8,0.21,1.16l-1.85,1.85l-1.78-1.78l0.71-0.71L9.88,5.61L12,3.49 c-1.17-1.17-3.07-1.17-4.24,0L4.22,7.03l1.41,1.41H2.81L2.1,9.15l3.54,3.54l0.71-0.71V9.15l1.41,1.41l0.71-0.71l1.78,1.78 l-7.41,7.41l2.12,2.12L16.34,9.79C16.7,9.92,17.09,10,17.5,10z'/></g></g></svg>
        <p id='please-send'>
          <b>We'd love to handle your orders!</b>
          <br />
          Please send us a message in one of the channels below with:
        </p>
        <div id='contact'>
          <a href='https://www.instagram.com/ohmybrand_official/'>Instagram</a>
        </div>
        <p id='in-site'>Or using our in-site chat below</p>
        <div id='steps'>
          <div className='step'>
            <div>1</div>
            <p className='step-text'>One Or More Pictures Of Your Pet</p>
          </div>
          <div className='step'>
            <div>2</div>
            <p className='step-text'>Requested Product Names</p>
            <p>{productName}</p>
          </div>
          <div className='step'>
            <div>3</div>
            <p className='step-text'>Product Size and Color</p>
          </div>
        </div>
      </section>
    </section>
  </>
}

export const getServerSideProps = async (ctx) => {
  console.log('getting initial props for maintenance', ctx.query)
  return { props: {productName: ctx.query.product} }
}
