import { Component } from 'react'
import styles from './index.module.sass'
import { BasePicture, Slider } from '../_shared'
import sets from './sets.yaml'
import { Autoplay } from 'swiper'

let Picture = new BasePicture('/rnd/subhero')

export class SubHero extends Component {
  render() {
    return <section id={styles.section}>
      <h3>A new fashion world for every pet owner</h3>
      <p>
        Turn your pet photo into stunning custom PJ's
        &amp;&nbsp;products with your pet character cartoon
      </p>
      <div id={styles.demo}>
        {/* <Picture asset='girl-dog' ext='png' />
        <Picture id={styles.avatar} asset='avatar' ext='png' /> */}
        <img src='/rnd/subhero/girl-dog@3x.png' />
        <img id={styles.avatar} src='/rnd/subhero/avatar@3x.png' />
      </div>
      <Slider itemComp={Set}
        items={sets}
        keyName='image'
        {...this.sliderOptions}
      />
      <a id={styles.button} href='/catalog'>Let's get started</a>
    </section>
  }

  sliderOptions = {
    modules: [
      Autoplay,
    ],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 2,
    loop: true,
  }
}

function Set({ image, ext, brand, set }) {
  return <div className={styles.set}>
    <div className={styles.imageContainer}>
      {/* <Picture asset={image} ext={ext} /> */}
      <img src={`/rnd/subhero/${image}@3x.${ext}`} />
    </div>
    <span className={styles.names}>
      <span className={styles.brand}>{ brand }</span>
      <span>{ set }</span>
    </span>
  </div>
}
