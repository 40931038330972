import { Component } from 'react'
import styles from './index.module.sass'
import steps from './steps.yaml'
import { List } from '../_shared'

let asset = name => `/rnd/how-it-works/${name}`

export class HowItWorks extends Component {
  render() {
    return <section id={styles.section}>
      <h2 id={styles.title}>How it works</h2>
      <List id={styles.steps}
        itemComp={Step}
        items={steps}
        keyName='step'
      />
      <a id={styles.button} href='/catalog'>Let’s get started</a>
    </section>
  }
}

function Step({ img, header, text }) {
  return <div className={styles.step}>
    <img src={asset(img)} className={styles.img}></img>
    <h3 className={styles.header}>{header}</h3>
    <p className={styles.text}>{text}</p>
    <img src='/rnd/how-it-works/shape.svg' className={styles.arrow}/>
  </div>
}
