import { Route, Switch } from "react-router-dom";
import Maitenance from './Maitenance';
import Main from './Main';

function Router() {
  return (
    <Switch>
      <Route path="/" exact>
        <Main />
      </Route>
      <Route path="*">
        <Maitenance />
      </Route>
    </Switch>
  )
}

export default Router